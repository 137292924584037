<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Locations</span>
        </h3>
        <div class="card-toolbar">
          <router-link
            to="/locations/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Map/Position.svg" />
            </span>
            <span>Add New Location</span>
          </router-link>
        </div>
      </div>

      <div class="card-body py-0">
        <div v-if="!isLoading" class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-uppercase">
                <th class="pl-6">
                  <span>Location name</span>
                </th>
                <th>
                  <span>Address</span>
                </th>
                <th v-if="currentUserCompanyModule[0] === 'gaming'">
                  <span>Status</span>
                </th>
                <th v-if="currentUserCompanyModule[0] === 'gaming'">
                  <span>{{ $t('GENERAL.LESSOR') }}</span>
                </th>
                <th class="text-right pr-6">
                  <span>{{ $t('GENERAL.ACTIONS') }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(location, key) in locations" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <router-link
                    :to="'/locations/edit/' + location.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ location.title }}</router-link
                  >
                  <span
                    class="text-muted font-weight-bold text-muted d-block"
                    >{{ location.description }}</span
                  >
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <div
                    class="d-flex flex-column w-100 mr-2 text-muted font-weight-500"
                  >
                    {{ location.address.name }}
                  </div>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <div :class="['mr-2', 'label', 'label-lg', 'label-inline', location.isActive ? 'label-light-success' : 'label-light-danger']">
                    {{ location.isActive ? 'Active' : 'Disabled' }}
                  </div>
                </td>
                <td
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <div
                    v-if="location.lessor"
                    class="d-flex flex-column w-100 mr-2 text-muted font-weight-500"
                  >
                    {{ location.lessor.name }}
                  </div>
                </td>
                <td
                  class="text-right pr-6"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <router-link
                    :to="'/locations/edit/' + location.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md" 
                    v-b-tooltip.hover.top="$t('LOCATIONS.EDIT_LOCATION')">
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <a
                    @click="deleteEntity(location.id)"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md" v-b-tooltip.hover.top="$t('LOCATIONS.DELETE_LOCATION')">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
          >
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="pagination.totalPages"
              use-router
              size="lg"
            ></b-pagination-nav>
          </div>
        </div>
        <div v-else class="col-12 text-center p-5">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'LocationsList',
  data() {
    return {
      locations: [],
      isLoading: true,
      pagination: {
        totalPages: 0,
        itemsPerPage: 30,
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
    ]),
    page() {
      return this.$route.query.page || 1
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Locations', route: '' }])
    this.getLocations()
  },
  watch: {
    page() {
      this.getLocations()
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getLocations() {
      const currentPage = this.$route.query.page || 1
      this.isLoading = true

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          'locations',
          `?itemsPerPage=${this.pagination.itemsPerPage}&page=${currentPage}`
        )
          .then(({ data }) => {
            this.locations = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'locations',
          `?company=${this.currentUserCompany}&itemsPerPage=${this.pagination.itemsPerPage}&page=${currentPage}`
        )
          .then(({ data }) => {
            this.locations = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    deleteEntity(id) {
      let confirmation = confirm('Zelite izbrisati ovog korisnika?')
      if (confirmation === false) return

      this.isLoading = true
      ApiService.delete(`locations/${id}`)
        .then(() => {
          if (this.currentUserRole === 'ROLE_ROOT') {
            ApiService.get('locations').then((response) => {
              this.locations = [...response.data['hydra:member']]
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
